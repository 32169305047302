import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {environment} from '../../environments/environment';
import { catchError, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
  })
export class YoutubeService {

    constructor(public httpClient:HttpClient){}
    private API_KEY = environment.google_api_key;
    private CHANNEL_ID = "";
    public URL_INVIDIOUS="";

    getVideosFromChannel(maxResults):Observable<any> {
      let url = 'https://www.googleapis.com/youtube/v3/search?key=' + this.API_KEY + '&channelId=' + this.CHANNEL_ID + '&order=date&part=snippet &type=video,id&maxResults=' + maxResults;
      return this.httpClient.get<any>(url);
    }

    getVideosFromInvidiousApi(maxResults):Observable<any> {
      const url = 'https://configurazioni.vercel.app/proxy'
      return this.httpClient.get<any>(url);
    }

    getUrlForVideos(){
      const url = 'https://configurazioni.vercel.app/config';
      return this.httpClient.get<any>(url).pipe(
        catchError((error)=>{
          console.error('Failed to load config:', error);
          return of({});
        })
      ).toPromise().then((data) => {
        this.URL_INVIDIOUS = data.url_servizio;
        this.CHANNEL_ID = data.channelId;
      });
    }


}