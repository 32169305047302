import { of } from 'rxjs';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class YoutubeService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.API_KEY = environment.google_api_key;
        this.CHANNEL_ID = "";
        this.URL_INVIDIOUS = "";
    }
    getVideosFromChannel(maxResults) {
        let url = 'https://www.googleapis.com/youtube/v3/search?key=' + this.API_KEY + '&channelId=' + this.CHANNEL_ID + '&order=date&part=snippet &type=video,id&maxResults=' + maxResults;
        return this.httpClient.get(url);
    }
    getVideosFromInvidiousApi(maxResults) {
        const url = 'https://configurazioni.vercel.app/proxy';
        return this.httpClient.get(url);
    }
    getUrlForVideos() {
        const url = 'https://configurazioni.vercel.app/config';
        return this.httpClient.get(url).pipe(catchError((error) => {
            console.error('Failed to load config:', error);
            return of({});
        })).toPromise().then((data) => {
            this.URL_INVIDIOUS = data.url_servizio;
            this.CHANNEL_ID = data.channelId;
        });
    }
}
YoutubeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function YoutubeService_Factory() { return new YoutubeService(i0.ɵɵinject(i1.HttpClient)); }, token: YoutubeService, providedIn: "root" });
